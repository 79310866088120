<template>
  <div class="subject-coverage flex">
    <div class="left-section white card-border card-radius" v-if="subjectListData.length > 0">
      <h2 class="secondary-header">Subject Coverage</h2>
      <div class="subject-select card-border card-radius" v-if="subjectListData.length > 0">
        <a class="fs-12 fw-600 skyblue-text flex items-center" @click="showSubjectList = false"
          >Select Subject<i class="material-icons fs-15 black-text" style="margin-left: 8px"
            >expand_more</i
          ></a
        >
        <h1 class="fs-20 fw-700 black-text" style="margin-top: 4px">
          {{ selectedSub.length > 12 ? selectedSub.slice(0, 12) + ".." : selectedSub }}
        </h1>
        <div
          style="width: 41%; height: 63px; position: absolute; top: 3px; right: 0; overflow: hidden"
        >
          <img
            :src="selectedSubIconUrl ? selectedSubIconUrl : randomImage"
            alt=""
            class="subject-img"
          />
        </div>
        <div class="subject-list scroll-verticle" v-if="!showSubjectList">
          <a class="fs-12 fw-600 skyblue-text flex items-center" @click="showSubjectList = true"
            >Select Subject<i class="material-icons fs-15 black-text" style="margin-left: 8px"
              >expand_less</i
            ></a
          >
          <div class="sublist">
            <p v-for="(item, index) in subjectListData" :key="index" @click="selectSubject(item)">
              {{
                item.subjectName.length > 12
                  ? item.subjectName.slice(0, 12) + ".."
                  : item.subjectName
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="chapter-wise-detail" v-if="Object.keys(chapterwiseFilterList).length > 0">
        <h2 class="secondary-header">Chapter-wise Details</h2>
        <div v-dragscroll class="scroll-horizontal flex">
          <a
            v-for="(chapterFilter, index) in Object.values(chapterwiseFilterList)"
            :key="index"
            class="gray-btn"
            style="margin-right: 10px"
            :class="chapterFilter.isDefault ? 'active' : ''"
            @click="filterChapterCards(chapterFilter, index)"
            >{{ chapterFilter.displayName }}</a
          >
        </div>
      </div>
      <div class="chapter-wise-content scroll-verticle" v-if="chapterwiseCardsList.length > 0">
        <div
          class="chapter-wise-card card-border card-radius"
          v-for="(card, index) in chapterwiseCardsList"
          :key="index"
          style="margin-bottom: 15px"
        >
          <div class="flex flex-between items-center">
            <h3 class="fs-12 fw-600 black-text truncate" style="line-height: 14px">
              {{ card.chapterName }}
            </h3>
            <!-- <a class="yellow-btn" style="width: 94px;">Practice Now</a> -->
          </div>
          <h4 class="progress-value fs-11 fw-700" style="color: #ee9b05">
            {{ (card.gaugeValue * 100).toFixed(0) }}%
          </h4>
          <div class="progress1">
            <!-- <div class="progress-bar1 red" :style="`width: ${card.gaugeValue*100}%`"  aria-valuemin="0" aria-valuemax="100"> -->
            <div
              class="progress-bar1"
              role="progressbar"
              :style="`width: ${parseInt((card.gaugeValue * 100).toFixed(0))}%`"
              v-bind:aria-valuenow="parseInt((card.gaugeValue * 100).toFixed(0))"
              aria-valuemin="0"
              aria-valuemax="100"
              :class="[
                {
                  transparent:
                    parseInt((card.gaugeValue * 100).toFixed(0)) == 0 ||
                    parseInt((card.gaugeValue * 100).toFixed(0)) == '',
                },
                {
                  red:
                    parseInt((card.gaugeValue * 100).toFixed(0)) <= 50 &&
                    parseInt((card.gaugeValue * 100).toFixed(0)) > 0,
                },
                {
                  green:
                    parseInt((card.gaugeValue * 100).toFixed(0)) <= 100 &&
                    parseInt((card.gaugeValue * 100).toFixed(0)) > 50,
                },
              ]"
            ></div>
            <!-- </div> -->
          </div>
          <div class="flex flex-between" style="padding-right: 3px">
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.0025 14.9257C13.3929 14.4406 15.999 11.5248 15.999 8.00025C15.999 4.13628 12.8667 1.00391 9.00269 1.00391C5.13872 1.00391 2.00635 4.13628 2.00635 8.00025C2.00635 11.2469 4.21774 13.977 7.21644 14.7665L10.0025 16.9953V14.9257ZM11.1935 9.09031C11.3568 8.76262 11.4487 8.39307 11.4487 8.00208C11.4487 6.65276 10.3548 5.55891 9.0055 5.55891C7.65617 5.55891 6.56233 6.65276 6.56233 8.00208C6.56233 9.3514 7.65617 10.4452 9.0055 10.4452C9.54604 10.4452 10.0456 10.2697 10.4502 9.97252L9.01533 8.82461C8.76666 8.62567 8.72634 8.26281 8.92528 8.01414C9.12421 7.76547 9.48707 7.72515 9.73575 7.92409L11.1935 9.09031ZM12.162 9.86512C12.4849 9.31921 12.6702 8.68229 12.6702 8.00208C12.6702 5.97809 11.0295 4.33733 9.0055 4.33733C6.98151 4.33733 5.34075 5.97809 5.34075 8.00208C5.34075 10.0261 6.98151 11.6668 9.0055 11.6668C9.93339 11.6668 10.7807 11.322 11.4264 10.7535L11.8984 11.1311C12.1471 11.33 12.5099 11.2897 12.7089 11.041C12.9078 10.7924 12.8675 10.4295 12.6188 10.2306L12.162 9.86512Z"
                  :fill="card.Attempted.color"
                  style="margin-right: 7px"
                />
              </svg>
              <span :style="`color: ${card.Attempted.color}`">{{ card.Attempted.value }}</span>
            </p>
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                  :fill="card.TimeTaken.color"
                />
                <path
                  d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                  :fill="card.TimeTaken.color"
                  style="margin-right: 7px"
                />
              </svg>
              <span :style="`color: ${card.TimeTaken.color}`">{{ card.TimeTaken.value }}</span>
            </p>
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                  :fill="card.Accuracy.color"
                />
              </svg>
              <span :style="`color: ${card.Accuracy.color}`">{{ card.Accuracy.value }}</span>
            </p>
            <p class="fs-13 fw-700 skyblue-text2 flex items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.00078 16.0312C12.4602 16.0312 15.2727 13.2047 15.2727 9.73125C15.2727 8.19844 14.7242 6.77812 13.8102 5.69531L14.1898 5.31562C14.4148 5.09062 14.4148 4.73906 14.1898 4.51406C13.9648 4.28906 13.6133 4.28906 13.3883 4.51406L13.0086 4.89375C12.0664 4.07812 10.8711 3.55781 9.56328 3.44531V3.09375H9.78828C10.0977 3.09375 10.3508 2.84062 10.3508 2.53125C10.3508 2.22187 10.0977 1.96875 9.78828 1.96875H8.21328C7.90391 1.96875 7.65078 2.22187 7.65078 2.53125C7.65078 2.84062 7.90391 3.09375 8.21328 3.09375H8.43828V3.44531C7.13047 3.55781 5.93516 4.07812 4.97891 4.89375L4.59922 4.5C4.37422 4.275 4.02266 4.275 3.79766 4.5C3.57266 4.725 3.57266 5.07656 3.79766 5.30156L4.17734 5.68125C3.26328 6.77813 2.71484 8.18437 2.71484 9.71719C2.72891 13.2047 5.54141 16.0312 9.00078 16.0312ZM9.00078 4.26094C11.9961 4.26094 14.4289 6.70781 14.4289 9.73125C14.4289 12.7547 11.9961 15.1875 9.00078 15.1875C6.00547 15.1875 3.57266 12.7406 3.57266 9.73125C3.57266 6.72187 6.00547 4.26094 9.00078 4.26094Z"
                  :fill="card.AvgTime.color"
                />
                <path
                  d="M8.99922 13.9355C11.3195 13.9355 13.1898 12.0512 13.1898 9.7168C13.1898 7.38242 11.3055 5.49805 8.99922 5.49805C6.69297 5.49805 4.80859 7.38242 4.80859 9.7168C4.80859 12.0512 6.67891 13.9355 8.99922 13.9355ZM8.57734 8.73242V6.73555C8.57734 6.49648 8.76016 6.31367 8.99922 6.31367C9.23828 6.31367 9.42109 6.49648 9.42109 6.73555V8.73242C9.80078 8.90117 10.068 9.28086 10.068 9.7168C10.068 10.3074 9.58984 10.7855 8.99922 10.7855C8.40859 10.7855 7.93047 10.3074 7.93047 9.7168C7.93047 9.28086 8.19766 8.90117 8.57734 8.73242Z"
                  :fill="card.AvgTime.color"
                />
                <line
                  x1="3"
                  y1="9.5"
                  x2="1"
                  y2="9.5"
                  :stroke="card.AvgTime.color"
                  stroke-linecap="round"
                />
                <line
                  x1="7.5"
                  y1="15.5"
                  x2="4.5"
                  y2="15.5"
                  :stroke="card.AvgTime.color"
                  stroke-linecap="round"
                />
                <line
                  x1="3.5"
                  y1="12.5"
                  x2="2.5"
                  y2="12.5"
                  :stroke="card.AvgTime.color"
                  stroke-linecap="round"
                />
                <line
                  x1="1"
                  y1="12.5"
                  x2="0.5"
                  y2="12.5"
                  :stroke="card.AvgTime.color"
                  stroke-linecap="round"
                />
                <line
                  x1="3"
                  y1="15.5"
                  x2="2"
                  y2="15.5"
                  :stroke="card.AvgTime.color"
                  stroke-linecap="round"
                />
              </svg>
              <span :style="`color: ${card.AvgTime.color}`">{{ card.AvgTime.value }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="middle-section">
      <!-- Overview -->
      <div class="middle-card-box white card-radius card-border" v-if="overviewCardList.length > 0">
        <h2 class="secondary-header">Overview</h2>
        <div class="card-content">
          <div
            class="card-box card-radius card-border"
            v-for="(cardlist, index) in overviewCardList"
            :key="index"
          >
            <h3 class="fs-12 fw-700 black-text">{{ cardlist.title }}</h3>
            <h4 class="fs-20 fw-700">{{ cardlist.value }}</h4>
          </div>
        </div>
      </div>
      <!-- Practice -->
      <div class="recommendation practice" v-if="this.getNewStudentDashboardpra.length > 0">
        <div class="recommend-top flex items-center">
          <h2 class="fs-15 purple-text fw-700">Practice</h2>
          <!-- <div v-dragscroll="true" class="scroll-horizontal" ref="scroll_container5" @mousewheel.prevent="scrollX5">
                                  <button class="gray-btn" :class="{ active: isActive }" @click="allPractice()">All</button>
                                  <div v-for="(subject, index) in getNewStudentDashboardsub" :key="subject.subjectId" :value="subject.subjectId" @click="getsubdata(subject, index)">
                                      <button class="gray-btn" :class="{ 'active': activeIndex === index }">{{ subject.subjectName}}</button>
                                  </div>
                              </div> -->
        </div>
        <div
          v-dragscroll
          v-if="getNewStudentDashboardpra.length > 0"
          class="recommendation-content flex scroll-horizontal"
        >
          <div
            class="main-recommendation-box"
            v-for="(Chapter, index) in getNewStudentDashboardpra"
            :key="index"
            :value="Chapter.chapterId"
            ref="slider"
          >
            <div
              class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
              style="padding: 12px; overflow: hidden"
            >
              <h2 class="fs-12 dark-text fw-600" unselectable="on">
                {{
                  Chapter.chapterName.length > 12
                    ? Chapter.chapterName.slice(0, 12) + ".."
                    : Chapter.chapterName
                }}
              </h2>
              <div class="flex flex-between">
                <p class="fs-12 fw-600" style="opacity: 0.6">
                  {{
                    Chapter.subjectName.length > 12
                      ? Chapter.subjectName.slice(0, 12) + ".."
                      : Chapter.subjectName
                  }}
                </p>
                <img
                  class="practice-image"
                  :src="Chapter.subjectIcon ? Chapter.subjectIcon : randomImage"
                />
                <button
                  class="yellow-btn"
                  style="width: 51px; height: 21px; margin-right: 10px"
                  @click="startPractice(Chapter)"
                >
                  Start
                </button>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`height: ${parseInt(Chapter.gaugeValue * 100)}%`"
                  v-bind:aria-valuenow="parseInt(Chapter.gaugeValue * 100)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :class="[
                    {
                      transparent:
                        parseInt(Chapter.gaugeValue * 100) == 0 ||
                        parseInt(Chapter.gaugeValue * 100) == '',
                    },
                    {
                      red:
                        parseInt(Chapter.gaugeValue * 100) <= 50 &&
                        parseInt(Chapter.gaugeValue * 100) > 0,
                    },
                    {
                      green:
                        parseInt(Chapter.gaugeValue * 100) <= 100 &&
                        parseInt(Chapter.gaugeValue * 100) > 50,
                    },
                  ]"
                ></div>
              </div>
            </div>
            <div
              class="flex flex-between fs-11 fw-600"
              style="margin-top: 5px"
              v-if="Object.keys(Chapter.reason).length === 2"
            >
              <p class="flex items-end" v-if="Chapter.reason.Overall !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  :fill="Chapter.reason.Overall.color"
                  style="margin-right: 4px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.99983 5.42822C8.21245 5.42822 7.57129 6.06938 7.57129 6.85676C7.57129 7.64415 8.21245 8.2853 8.99983 8.2853C9.78721 8.2853 10.4284 7.64415 10.4284 6.85676C10.4284 6.06938 9.78721 5.42822 8.99983 5.42822Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                  <path
                    d="M13.9998 6.85683C13.9998 6.13693 13.5892 5.51827 12.9902 5.20332C13.1899 4.55935 13.0437 3.83102 12.5347 3.32203C12.0257 2.81304 11.2974 2.66681 10.6534 2.86647C10.3384 2.2675 9.71979 1.85693 8.99989 1.85693C8.28 1.85693 7.66134 2.2675 7.34638 2.86647C6.70242 2.66681 5.97409 2.81304 5.4651 3.32203C4.95611 3.83102 4.80988 4.55935 5.00954 5.20332C4.41338 5.51827 4 6.13412 4 6.85683C4 7.57672 4.41056 8.19538 5.00954 8.51033C4.80988 9.1543 4.95611 9.88263 5.4651 10.3916C5.71256 10.6391 6.01064 10.7966 6.3256 10.8725L4.73677 14.9135C4.64397 15.1497 4.883 15.3803 5.1164 15.279L6.26654 14.3763C6.40996 14.3145 6.57868 14.3792 6.64336 14.5254L6.86833 15.968C6.96956 16.2014 7.3042 16.1958 7.397 15.9596L9.00552 11.8708L10.614 15.9596C10.7068 16.1958 11.0415 16.2014 11.1427 15.968L11.3677 14.5254C11.4295 14.382 11.5983 14.3145 11.7445 14.3763L12.8918 15.279C13.1252 15.3803 13.3643 15.1497 13.2715 14.9135L11.6826 10.8697C11.9948 10.7909 12.2928 10.6335 12.5375 10.3888C13.0465 9.87982 13.1927 9.15149 12.9931 8.50752C13.5892 8.19538 13.9998 7.57672 13.9998 6.85683V6.85683ZM8.99989 9.71391C7.42512 9.71391 6.14281 8.43159 6.14281 6.85683C6.14281 5.28206 7.42512 3.99974 8.99989 3.99974C10.5747 3.99974 11.857 5.28206 11.857 6.85683C11.857 8.43159 10.5747 9.71391 8.99989 9.71391Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Overall !== undefined"
                  :style="`color: ${Chapter.reason.Overall.color}`"
                  >{{ Chapter.reason.Overall ? Chapter.reason.Overall.value : "" }}</span
                >
              </p>
              <p class="flex items-end" v-if="Chapter.reason.Accuracy !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 4px"
                >
                  <path
                    d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                    :fill="Chapter.reason.Accuracy.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Accuracy !== undefined"
                  :style="`color: ${Chapter.reason.Accuracy.color}`"
                  >{{ Chapter.reason.Accuracy ? Chapter.reason.Accuracy.value : "" }}</span
                >
              </p>
              <p class="flex items-end" v-if="Chapter.reason.Speed !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.9998 16.0312C12.4592 16.0312 15.2717 13.2047 15.2717 9.73125C15.2717 8.19844 14.7232 6.77812 13.8092 5.69531L14.1889 5.31562C14.4139 5.09062 14.4139 4.73906 14.1889 4.51406C13.9639 4.28906 13.6123 4.28906 13.3873 4.51406L13.0076 4.89375C12.0654 4.07812 10.8701 3.55781 9.5623 3.44531V3.09375H9.7873C10.0967 3.09375 10.3498 2.84062 10.3498 2.53125C10.3498 2.22187 10.0967 1.96875 9.7873 1.96875H8.21231C7.90293 1.96875 7.64981 2.22187 7.64981 2.53125C7.64981 2.84062 7.90293 3.09375 8.21231 3.09375H8.4373V3.44531C7.12949 3.55781 5.93418 4.07812 4.97793 4.89375L4.59824 4.5C4.37324 4.275 4.02168 4.275 3.79668 4.5C3.57168 4.725 3.57168 5.07656 3.79668 5.30156L4.17637 5.68125C3.2623 6.77813 2.71387 8.18437 2.71387 9.71719C2.72793 13.2047 5.54043 16.0312 8.9998 16.0312ZM8.9998 4.26094C11.9951 4.26094 14.4279 6.70781 14.4279 9.73125C14.4279 12.7547 11.9951 15.1875 8.9998 15.1875C6.00449 15.1875 3.57168 12.7406 3.57168 9.73125C3.57168 6.72187 6.00449 4.26094 8.9998 4.26094Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <path
                    d="M9.0002 13.936C11.3205 13.936 13.1908 12.0517 13.1908 9.71729C13.1908 7.38291 11.3064 5.49854 9.0002 5.49854C6.69395 5.49854 4.80957 7.38291 4.80957 9.71729C4.80957 12.0517 6.67988 13.936 9.0002 13.936ZM8.57832 8.73291V6.73604C8.57832 6.49697 8.76113 6.31416 9.0002 6.31416C9.23926 6.31416 9.42207 6.49697 9.42207 6.73604V8.73291C9.80176 8.90166 10.0689 9.28135 10.0689 9.71729C10.0689 10.3079 9.59082 10.786 9.0002 10.786C8.40957 10.786 7.93145 10.3079 7.93145 9.71729C7.93145 9.28135 8.19863 8.90166 8.57832 8.73291Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <line
                    x1="3"
                    y1="9.5"
                    x2="1"
                    y2="9.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="7.5"
                    y1="15.5"
                    x2="4.5"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3.5"
                    y1="12.5"
                    x2="2.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="1"
                    y1="12.5"
                    x2="0.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3"
                    y1="15.5"
                    x2="2"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Speed !== undefined"
                  :style="`color: ${Chapter.reason.Speed.color}`"
                  >{{ Chapter.reason.Speed ? Chapter.reason.Speed.value : "" }}</span
                >
              </p>
              <p class="flex items-end" v-if="Chapter.reason.Effort !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                  <path
                    d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Effort !== undefined"
                  :style="`color:${Chapter.reason.Effort.color}`"
                  >{{ Chapter.reason.Effort.value ? Chapter.reason.Effort.value : "" }}</span
                >
              </p>
            </div>
            <div
              class="flex flex-between"
              style="margin-top: 5px"
              v-if="Object.keys(Chapter.reason).length === 1"
            >
              <p class="fs-11 fw-600 flex items-end">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  :fill="Chapter.reason.Overall.color"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="Chapter.reason.Overall !== undefined"
                >
                  <path
                    d="M8.99983 5.42822C8.21245 5.42822 7.57129 6.06938 7.57129 6.85676C7.57129 7.64415 8.21245 8.2853 8.99983 8.2853C9.78721 8.2853 10.4284 7.64415 10.4284 6.85676C10.4284 6.06938 9.78721 5.42822 8.99983 5.42822Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                  <path
                    d="M13.9998 6.85683C13.9998 6.13693 13.5892 5.51827 12.9902 5.20332C13.1899 4.55935 13.0437 3.83102 12.5347 3.32203C12.0257 2.81304 11.2974 2.66681 10.6534 2.86647C10.3384 2.2675 9.71979 1.85693 8.99989 1.85693C8.28 1.85693 7.66134 2.2675 7.34638 2.86647C6.70242 2.66681 5.97409 2.81304 5.4651 3.32203C4.95611 3.83102 4.80988 4.55935 5.00954 5.20332C4.41338 5.51827 4 6.13412 4 6.85683C4 7.57672 4.41056 8.19538 5.00954 8.51033C4.80988 9.1543 4.95611 9.88263 5.4651 10.3916C5.71256 10.6391 6.01064 10.7966 6.3256 10.8725L4.73677 14.9135C4.64397 15.1497 4.883 15.3803 5.1164 15.279L6.26654 14.3763C6.40996 14.3145 6.57868 14.3792 6.64336 14.5254L6.86833 15.968C6.96956 16.2014 7.3042 16.1958 7.397 15.9596L9.00552 11.8708L10.614 15.9596C10.7068 16.1958 11.0415 16.2014 11.1427 15.968L11.3677 14.5254C11.4295 14.382 11.5983 14.3145 11.7445 14.3763L12.8918 15.279C13.1252 15.3803 13.3643 15.1497 13.2715 14.9135L11.6826 10.8697C11.9948 10.7909 12.2928 10.6335 12.5375 10.3888C13.0465 9.87982 13.1927 9.15149 12.9931 8.50752C13.5892 8.19538 13.9998 7.57672 13.9998 6.85683V6.85683ZM8.99989 9.71391C7.42512 9.71391 6.14281 8.43159 6.14281 6.85683C6.14281 5.28206 7.42512 3.99974 8.99989 3.99974C10.5747 3.99974 11.857 5.28206 11.857 6.85683C11.857 8.43159 10.5747 9.71391 8.99989 9.71391Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 4px"
                  v-if="Chapter.reason.Accuracy !== undefined"
                >
                  <path
                    d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                    :fill="Chapter.reason.Accuracy.color"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="Chapter.reason.Speed !== undefined"
                >
                  <path
                    d="M8.9998 16.0312C12.4592 16.0312 15.2717 13.2047 15.2717 9.73125C15.2717 8.19844 14.7232 6.77812 13.8092 5.69531L14.1889 5.31562C14.4139 5.09062 14.4139 4.73906 14.1889 4.51406C13.9639 4.28906 13.6123 4.28906 13.3873 4.51406L13.0076 4.89375C12.0654 4.07812 10.8701 3.55781 9.5623 3.44531V3.09375H9.7873C10.0967 3.09375 10.3498 2.84062 10.3498 2.53125C10.3498 2.22187 10.0967 1.96875 9.7873 1.96875H8.21231C7.90293 1.96875 7.64981 2.22187 7.64981 2.53125C7.64981 2.84062 7.90293 3.09375 8.21231 3.09375H8.4373V3.44531C7.12949 3.55781 5.93418 4.07812 4.97793 4.89375L4.59824 4.5C4.37324 4.275 4.02168 4.275 3.79668 4.5C3.57168 4.725 3.57168 5.07656 3.79668 5.30156L4.17637 5.68125C3.2623 6.77813 2.71387 8.18437 2.71387 9.71719C2.72793 13.2047 5.54043 16.0312 8.9998 16.0312ZM8.9998 4.26094C11.9951 4.26094 14.4279 6.70781 14.4279 9.73125C14.4279 12.7547 11.9951 15.1875 8.9998 15.1875C6.00449 15.1875 3.57168 12.7406 3.57168 9.73125C3.57168 6.72187 6.00449 4.26094 8.9998 4.26094Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <path
                    d="M9.0002 13.936C11.3205 13.936 13.1908 12.0517 13.1908 9.71729C13.1908 7.38291 11.3064 5.49854 9.0002 5.49854C6.69395 5.49854 4.80957 7.38291 4.80957 9.71729C4.80957 12.0517 6.67988 13.936 9.0002 13.936ZM8.57832 8.73291V6.73604C8.57832 6.49697 8.76113 6.31416 9.0002 6.31416C9.23926 6.31416 9.42207 6.49697 9.42207 6.73604V8.73291C9.80176 8.90166 10.0689 9.28135 10.0689 9.71729C10.0689 10.3079 9.59082 10.786 9.0002 10.786C8.40957 10.786 7.93145 10.3079 7.93145 9.71729C7.93145 9.28135 8.19863 8.90166 8.57832 8.73291Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <line
                    x1="3"
                    y1="9.5"
                    x2="1"
                    y2="9.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="7.5"
                    y1="15.5"
                    x2="4.5"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3.5"
                    y1="12.5"
                    x2="2.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="1"
                    y1="12.5"
                    x2="0.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3"
                    y1="15.5"
                    x2="2"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="Chapter.reason.Effort !== undefined"
                >
                  <path
                    d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                  <path
                    d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Overall !== undefined"
                  :style="`color: ${Chapter.reason.Overall.color}`"
                  >{{ Chapter.reason.Overall ? Chapter.reason.Overall.value : "" }}</span
                >
                <span
                  v-if="Chapter.reason.Speed !== undefined"
                  :style="`color: ${Chapter.reason.Speed.color}`"
                  >{{ Chapter.reason.Speed ? Chapter.reason.Speed.value : "" }}</span
                >
                <span
                  v-if="Chapter.reason.Accuracy !== undefined"
                  :style="`color: ${Chapter.reason.Accuracy.color}`"
                  >{{ Chapter.reason.Accuracy ? Chapter.reason.Accuracy.value : "" }}</span
                >
                <span
                  v-if="Chapter.reason.Effort !== undefined"
                  :style="`color:${Chapter.reason.Effort.color}`"
                  >{{ Chapter.reason.Effort.value ? Chapter.reason.Effort.value : "" }}</span
                >
              </p>
            </div>
          </div>
          <div class="main-recommendation-box">
            <div
              class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
              style="padding: 12px; overflow: hidden; background: #e3e3e3 !important"
            >
              <div class="flex flex-between flex-column items-center">
                <img
                  style="
                    height: 25px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    width: max-content;
                  "
                  :src="require(`../assets/newsession.svg`)"
                  @click="startNewPractice(Chapter)"
                />
                <p class="fs-12 fw-600 center-align" style="opacity: 0.6">
                  Start Your Own Practice
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.getNewStudentDashboardpra.length === 0">
          <div class="main-recommendation-box">
            <div
              class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
              style="padding: 12px; overflow: hidden; background: #e3e3e3 !important"
            >
              <div class="flex flex-between flex-column">
                <img
                  style="height: 25px; margin-top: 5px; margin-bottom: 5px"
                  :src="require(`../assets/newsession.svg`)"
                  @click="startNewPractice(Chapter)"
                />
                <p class="fs-12 fw-600" style="opacity: 0.6; margin-left: 5px">
                  Start Your Own Practice
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Self Test -->
      <div class="recommendation practice" v-if="this.selfTest.length > 0">
        <div class="recommend-top flex items-center">
          <h2 class="fs-15 purple-text fw-700">Self Test</h2>
          <!-- <div v-dragscroll="true" class="scroll-horizontal" ref="scroll_container5" @mousewheel.prevent="scrollX5">
                                  <button class="gray-btn" :class="{ active: isActive }" @click="allPractice()">All</button>
                                  <div v-for="(subject, index) in getNewStudentDashboardsub" :key="subject.subjectId" :value="subject.subjectId" @click="getsubdata(subject, index)">
                                      <button class="gray-btn" :class="{ 'active': activeIndex === index }">{{ subject.subjectName}}</button>
                                  </div>
                              </div> -->
        </div>
        <div
          v-dragscroll
          v-if="selfTest.length > 0"
          class="recommendation-content flex scroll-horizontal"
        >
          <div
            class="main-recommendation-box"
            v-for="(Chapter, index) in selfTest"
            :key="index"
            :value="Chapter.chapterId"
          >
            <div
              class="recommend-box recommendation-box relative card-radius-2"
              style="padding: 12px; overflow: hidden; background: #dceaff"
            >
              <h2 class="fs-12 dark-text fw-600" unselectable="on">
                {{
                  Chapter.chapterName.length > 12
                    ? Chapter.chapterName.slice(0, 12) + ".."
                    : Chapter.chapterName
                }}
              </h2>
              <div class="flex flex-between">
                <p class="fs-12 fw-600" style="opacity: 0.6">
                  {{
                    Chapter.subjectName.length > 12
                      ? Chapter.subjectName.slice(0, 12) + ".."
                      : Chapter.subjectName
                  }}
                </p>
                <img
                  class="practice-image"
                  :src="Chapter.subjectIcon ? Chapter.subjectIcon : randomImage"
                />
                <button
                  class="yellow-btn"
                  style="width: 51px; height: 21px; margin-right: 10px"
                  @click="startSelfTestFull(Chapter)"
                >
                  Start
                </button>
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`height: ${parseInt(Chapter.gaugeValue * 100)}%`"
                  v-bind:aria-valuenow="parseInt(Chapter.gaugeValue * 100)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :class="[
                    {
                      transparent:
                        parseInt(Chapter.gaugeValue * 100) == 0 ||
                        parseInt(Chapter.gaugeValue * 100) == '',
                    },
                    {
                      red:
                        parseInt(Chapter.gaugeValue * 100) <= 50 &&
                        parseInt(Chapter.gaugeValue * 100) > 0,
                    },
                    {
                      green:
                        parseInt(Chapter.gaugeValue * 100) <= 100 &&
                        parseInt(Chapter.gaugeValue * 100) > 50,
                    },
                  ]"
                ></div>
              </div>
            </div>
            <div
              class="flex flex-between fs-11 fw-600"
              style="margin-top: 5px"
              v-if="Object.keys(Chapter.reason).length === 2"
            >
              <p class="flex items-end" v-if="Chapter.reason.Overall !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  :fill="Chapter.reason.Overall.color"
                  style="margin-right: 4px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.99983 5.42822C8.21245 5.42822 7.57129 6.06938 7.57129 6.85676C7.57129 7.64415 8.21245 8.2853 8.99983 8.2853C9.78721 8.2853 10.4284 7.64415 10.4284 6.85676C10.4284 6.06938 9.78721 5.42822 8.99983 5.42822Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                  <path
                    d="M13.9998 6.85683C13.9998 6.13693 13.5892 5.51827 12.9902 5.20332C13.1899 4.55935 13.0437 3.83102 12.5347 3.32203C12.0257 2.81304 11.2974 2.66681 10.6534 2.86647C10.3384 2.2675 9.71979 1.85693 8.99989 1.85693C8.28 1.85693 7.66134 2.2675 7.34638 2.86647C6.70242 2.66681 5.97409 2.81304 5.4651 3.32203C4.95611 3.83102 4.80988 4.55935 5.00954 5.20332C4.41338 5.51827 4 6.13412 4 6.85683C4 7.57672 4.41056 8.19538 5.00954 8.51033C4.80988 9.1543 4.95611 9.88263 5.4651 10.3916C5.71256 10.6391 6.01064 10.7966 6.3256 10.8725L4.73677 14.9135C4.64397 15.1497 4.883 15.3803 5.1164 15.279L6.26654 14.3763C6.40996 14.3145 6.57868 14.3792 6.64336 14.5254L6.86833 15.968C6.96956 16.2014 7.3042 16.1958 7.397 15.9596L9.00552 11.8708L10.614 15.9596C10.7068 16.1958 11.0415 16.2014 11.1427 15.968L11.3677 14.5254C11.4295 14.382 11.5983 14.3145 11.7445 14.3763L12.8918 15.279C13.1252 15.3803 13.3643 15.1497 13.2715 14.9135L11.6826 10.8697C11.9948 10.7909 12.2928 10.6335 12.5375 10.3888C13.0465 9.87982 13.1927 9.15149 12.9931 8.50752C13.5892 8.19538 13.9998 7.57672 13.9998 6.85683V6.85683ZM8.99989 9.71391C7.42512 9.71391 6.14281 8.43159 6.14281 6.85683C6.14281 5.28206 7.42512 3.99974 8.99989 3.99974C10.5747 3.99974 11.857 5.28206 11.857 6.85683C11.857 8.43159 10.5747 9.71391 8.99989 9.71391Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Overall !== undefined"
                  :style="`color: ${Chapter.reason.Overall.color}`"
                  >{{ Chapter.reason.Overall ? Chapter.reason.Overall.value : "" }}</span
                >
              </p>
              <p class="flex items-end" v-if="Chapter.reason.Accuracy !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 4px"
                >
                  <path
                    d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                    :fill="Chapter.reason.Accuracy.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Accuracy !== undefined"
                  :style="`color: ${Chapter.reason.Accuracy.color}`"
                  >{{ Chapter.reason.Accuracy ? Chapter.reason.Accuracy.value : "" }}</span
                >
              </p>
              <p class="flex items-end" v-if="Chapter.reason.Speed !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.9998 16.0312C12.4592 16.0312 15.2717 13.2047 15.2717 9.73125C15.2717 8.19844 14.7232 6.77812 13.8092 5.69531L14.1889 5.31562C14.4139 5.09062 14.4139 4.73906 14.1889 4.51406C13.9639 4.28906 13.6123 4.28906 13.3873 4.51406L13.0076 4.89375C12.0654 4.07812 10.8701 3.55781 9.5623 3.44531V3.09375H9.7873C10.0967 3.09375 10.3498 2.84062 10.3498 2.53125C10.3498 2.22187 10.0967 1.96875 9.7873 1.96875H8.21231C7.90293 1.96875 7.64981 2.22187 7.64981 2.53125C7.64981 2.84062 7.90293 3.09375 8.21231 3.09375H8.4373V3.44531C7.12949 3.55781 5.93418 4.07812 4.97793 4.89375L4.59824 4.5C4.37324 4.275 4.02168 4.275 3.79668 4.5C3.57168 4.725 3.57168 5.07656 3.79668 5.30156L4.17637 5.68125C3.2623 6.77813 2.71387 8.18437 2.71387 9.71719C2.72793 13.2047 5.54043 16.0312 8.9998 16.0312ZM8.9998 4.26094C11.9951 4.26094 14.4279 6.70781 14.4279 9.73125C14.4279 12.7547 11.9951 15.1875 8.9998 15.1875C6.00449 15.1875 3.57168 12.7406 3.57168 9.73125C3.57168 6.72187 6.00449 4.26094 8.9998 4.26094Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <path
                    d="M9.0002 13.936C11.3205 13.936 13.1908 12.0517 13.1908 9.71729C13.1908 7.38291 11.3064 5.49854 9.0002 5.49854C6.69395 5.49854 4.80957 7.38291 4.80957 9.71729C4.80957 12.0517 6.67988 13.936 9.0002 13.936ZM8.57832 8.73291V6.73604C8.57832 6.49697 8.76113 6.31416 9.0002 6.31416C9.23926 6.31416 9.42207 6.49697 9.42207 6.73604V8.73291C9.80176 8.90166 10.0689 9.28135 10.0689 9.71729C10.0689 10.3079 9.59082 10.786 9.0002 10.786C8.40957 10.786 7.93145 10.3079 7.93145 9.71729C7.93145 9.28135 8.19863 8.90166 8.57832 8.73291Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <line
                    x1="3"
                    y1="9.5"
                    x2="1"
                    y2="9.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="7.5"
                    y1="15.5"
                    x2="4.5"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3.5"
                    y1="12.5"
                    x2="2.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="1"
                    y1="12.5"
                    x2="0.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3"
                    y1="15.5"
                    x2="2"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Speed !== undefined"
                  :style="`color: ${Chapter.reason.Speed.color}`"
                  >{{ Chapter.reason.Speed ? Chapter.reason.Speed.value : "" }}</span
                >
              </p>
              <p class="flex items-end" v-if="Chapter.reason.Effort !== undefined">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                  <path
                    d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Effort !== undefined"
                  :style="`color:${Chapter.reason.Effort.color}`"
                  >{{ Chapter.reason.Effort.value ? Chapter.reason.Effort.value : "" }}</span
                >
              </p>
            </div>
            <div
              class="flex flex-between"
              style="margin-top: 5px"
              v-if="Object.keys(Chapter.reason).length === 1"
            >
              <p class="fs-11 fw-600 flex items-end">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  :fill="Chapter.reason.Overall.color"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="Chapter.reason.Overall !== undefined"
                >
                  <path
                    d="M8.99983 5.42822C8.21245 5.42822 7.57129 6.06938 7.57129 6.85676C7.57129 7.64415 8.21245 8.2853 8.99983 8.2853C9.78721 8.2853 10.4284 7.64415 10.4284 6.85676C10.4284 6.06938 9.78721 5.42822 8.99983 5.42822Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                  <path
                    d="M13.9998 6.85683C13.9998 6.13693 13.5892 5.51827 12.9902 5.20332C13.1899 4.55935 13.0437 3.83102 12.5347 3.32203C12.0257 2.81304 11.2974 2.66681 10.6534 2.86647C10.3384 2.2675 9.71979 1.85693 8.99989 1.85693C8.28 1.85693 7.66134 2.2675 7.34638 2.86647C6.70242 2.66681 5.97409 2.81304 5.4651 3.32203C4.95611 3.83102 4.80988 4.55935 5.00954 5.20332C4.41338 5.51827 4 6.13412 4 6.85683C4 7.57672 4.41056 8.19538 5.00954 8.51033C4.80988 9.1543 4.95611 9.88263 5.4651 10.3916C5.71256 10.6391 6.01064 10.7966 6.3256 10.8725L4.73677 14.9135C4.64397 15.1497 4.883 15.3803 5.1164 15.279L6.26654 14.3763C6.40996 14.3145 6.57868 14.3792 6.64336 14.5254L6.86833 15.968C6.96956 16.2014 7.3042 16.1958 7.397 15.9596L9.00552 11.8708L10.614 15.9596C10.7068 16.1958 11.0415 16.2014 11.1427 15.968L11.3677 14.5254C11.4295 14.382 11.5983 14.3145 11.7445 14.3763L12.8918 15.279C13.1252 15.3803 13.3643 15.1497 13.2715 14.9135L11.6826 10.8697C11.9948 10.7909 12.2928 10.6335 12.5375 10.3888C13.0465 9.87982 13.1927 9.15149 12.9931 8.50752C13.5892 8.19538 13.9998 7.57672 13.9998 6.85683V6.85683ZM8.99989 9.71391C7.42512 9.71391 6.14281 8.43159 6.14281 6.85683C6.14281 5.28206 7.42512 3.99974 8.99989 3.99974C10.5747 3.99974 11.857 5.28206 11.857 6.85683C11.857 8.43159 10.5747 9.71391 8.99989 9.71391Z"
                    :fill="Chapter.reason.Overall.color"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-right: 4px"
                  v-if="Chapter.reason.Accuracy !== undefined"
                >
                  <path
                    d="M15.75 8.25H14.925C14.55 5.55 12.45 3.375 9.75 3.075V2.25C9.75 1.8 9.45 1.5 9 1.5C8.55 1.5 8.25 1.8 8.25 2.25V3.075C5.55 3.375 3.375 5.55 3.075 8.25H2.25C1.8 8.25 1.5 8.55 1.5 9C1.5 9.45 1.8 9.75 2.25 9.75H3.075C3.45 12.45 5.55 14.625 8.25 14.925V15.75C8.25 16.2 8.55 16.5 9 16.5C9.45 16.5 9.75 16.2 9.75 15.75V14.925C12.45 14.55 14.625 12.45 14.925 9.75H15.75C16.2 9.75 16.5 9.45 16.5 9C16.5 8.55 16.2 8.25 15.75 8.25ZM9 13.5C6.525 13.5 4.5 11.475 4.5 9C4.5 6.525 6.525 4.5 9 4.5C11.475 4.5 13.5 6.525 13.5 9C13.5 11.475 11.475 13.5 9 13.5ZM9 6.75C7.725 6.75 6.75 7.725 6.75 9C6.75 10.275 7.725 11.25 9 11.25C10.275 11.25 11.25 10.275 11.25 9C11.25 7.725 10.275 6.75 9 6.75Z"
                    :fill="Chapter.reason.Accuracy.color"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="Chapter.reason.Speed !== undefined"
                >
                  <path
                    d="M8.9998 16.0312C12.4592 16.0312 15.2717 13.2047 15.2717 9.73125C15.2717 8.19844 14.7232 6.77812 13.8092 5.69531L14.1889 5.31562C14.4139 5.09062 14.4139 4.73906 14.1889 4.51406C13.9639 4.28906 13.6123 4.28906 13.3873 4.51406L13.0076 4.89375C12.0654 4.07812 10.8701 3.55781 9.5623 3.44531V3.09375H9.7873C10.0967 3.09375 10.3498 2.84062 10.3498 2.53125C10.3498 2.22187 10.0967 1.96875 9.7873 1.96875H8.21231C7.90293 1.96875 7.64981 2.22187 7.64981 2.53125C7.64981 2.84062 7.90293 3.09375 8.21231 3.09375H8.4373V3.44531C7.12949 3.55781 5.93418 4.07812 4.97793 4.89375L4.59824 4.5C4.37324 4.275 4.02168 4.275 3.79668 4.5C3.57168 4.725 3.57168 5.07656 3.79668 5.30156L4.17637 5.68125C3.2623 6.77813 2.71387 8.18437 2.71387 9.71719C2.72793 13.2047 5.54043 16.0312 8.9998 16.0312ZM8.9998 4.26094C11.9951 4.26094 14.4279 6.70781 14.4279 9.73125C14.4279 12.7547 11.9951 15.1875 8.9998 15.1875C6.00449 15.1875 3.57168 12.7406 3.57168 9.73125C3.57168 6.72187 6.00449 4.26094 8.9998 4.26094Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <path
                    d="M9.0002 13.936C11.3205 13.936 13.1908 12.0517 13.1908 9.71729C13.1908 7.38291 11.3064 5.49854 9.0002 5.49854C6.69395 5.49854 4.80957 7.38291 4.80957 9.71729C4.80957 12.0517 6.67988 13.936 9.0002 13.936ZM8.57832 8.73291V6.73604C8.57832 6.49697 8.76113 6.31416 9.0002 6.31416C9.23926 6.31416 9.42207 6.49697 9.42207 6.73604V8.73291C9.80176 8.90166 10.0689 9.28135 10.0689 9.71729C10.0689 10.3079 9.59082 10.786 9.0002 10.786C8.40957 10.786 7.93145 10.3079 7.93145 9.71729C7.93145 9.28135 8.19863 8.90166 8.57832 8.73291Z"
                    :fill="Chapter.reason.Speed.color"
                  />
                  <line
                    x1="3"
                    y1="9.5"
                    x2="1"
                    y2="9.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="7.5"
                    y1="15.5"
                    x2="4.5"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3.5"
                    y1="12.5"
                    x2="2.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="1"
                    y1="12.5"
                    x2="0.5"
                    y2="12.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                  <line
                    x1="3"
                    y1="15.5"
                    x2="2"
                    y2="15.5"
                    :stroke="Chapter.reason.Speed.color"
                    stroke-linecap="round"
                  />
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="Chapter.reason.Effort !== undefined"
                >
                  <path
                    d="M13.3118 14.5001C13.2966 14.7524 13.0999 14.9719 12.8425 15.0198L12.8198 15.0249L5.56699 15.0223C5.27688 14.9769 5.07002 14.7423 5.07002 14.4648V12.1187C5.07002 11.5687 5.41311 11.0415 6.01099 10.6706L7.78195 9.57578C7.97872 9.45216 8.10234 9.22764 8.10234 8.98798C8.10234 8.75084 7.98125 8.52884 7.787 8.40523C7.08568 7.96123 6.54077 7.63328 6.01856 7.34064C5.89243 7.26748 5.5569 7.07575 5.31977 6.68725C5.12804 6.37443 5.0902 6.06918 5.07254 5.92286C4.98172 5.20136 5.01199 4.17461 5.05236 3.4405C5.07002 3.15795 5.30211 2.93848 5.57961 2.93848H12.7669C13.0595 2.93848 13.2966 3.17814 13.2966 3.4733V5.89007C13.2966 6.4375 12.9535 6.96223 12.3557 7.33307L10.5847 8.43046C10.4856 8.49315 10.404 8.57995 10.3475 8.68274C10.291 8.78554 10.2615 8.90097 10.2618 9.01825C10.2618 9.25539 10.3829 9.47739 10.5771 9.601C11.2785 10.0425 11.8234 10.3704 12.3456 10.6656C12.4717 10.7388 12.8097 10.9305 13.0469 11.3215C13.2159 11.604 13.2689 11.879 13.2941 12.0859C13.3975 12.8957 13.3395 14.0461 13.3118 14.5001Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                  <path
                    d="M14.147 15.0256L4.21956 15.0251C4.15265 15.0251 4.08848 14.9985 4.04117 14.9512C3.99386 14.9039 3.96729 14.8397 3.96729 14.7728C3.96729 14.7059 3.99386 14.6417 4.04117 14.5944C4.08848 14.5471 4.15265 14.5205 4.21956 14.5205L14.147 14.5211C14.2139 14.5211 14.2781 14.5476 14.3254 14.5949C14.3727 14.6423 14.3993 14.7064 14.3993 14.7733C14.3993 14.8402 14.3727 14.9044 14.3254 14.9517C14.2781 14.999 14.2139 15.0256 14.147 15.0256ZM14.147 3.42155L4.21956 3.42105C4.15265 3.42105 4.08848 3.39447 4.04117 3.34716C3.99386 3.29985 3.96729 3.23568 3.96729 3.16878C3.96729 3.10187 3.99386 3.0377 4.04117 2.99039C4.08848 2.94308 4.15265 2.9165 4.21956 2.9165L14.147 2.91701C14.2139 2.91701 14.2781 2.94359 14.3254 2.9909C14.3727 3.03821 14.3993 3.10237 14.3993 3.16928C14.3993 3.23619 14.3727 3.30035 14.3254 3.34767C14.2781 3.39498 14.2139 3.42155 14.147 3.42155Z"
                    :fill="Chapter.reason.Effort.color"
                  />
                </svg>
                <span
                  v-if="Chapter.reason.Overall !== undefined"
                  :style="`color: ${Chapter.reason.Overall.color}`"
                  >{{ Chapter.reason.Overall ? Chapter.reason.Overall.value : "" }}</span
                >
                <span
                  v-if="Chapter.reason.Speed !== undefined"
                  :style="`color: ${Chapter.reason.Speed.color}`"
                  >{{ Chapter.reason.Speed ? Chapter.reason.Speed.value : "" }}</span
                >
                <span
                  v-if="Chapter.reason.Accuracy !== undefined"
                  :style="`color: ${Chapter.reason.Accuracy.color}`"
                  >{{ Chapter.reason.Accuracy ? Chapter.reason.Accuracy.value : "" }}</span
                >
                <span
                  v-if="Chapter.reason.Effort !== undefined"
                  :style="`color:${Chapter.reason.Effort.color}`"
                  >{{ Chapter.reason.Effort.value ? Chapter.reason.Effort.value : "" }}</span
                >
              </p>
            </div>
          </div>
          <div class="main-recommendation-box">
            <div
              class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
              style="padding: 12px; overflow: hidden; background: #e3e3e3 !important"
            >
              <div class="flex flex-between flex-column items-center">
                <img
                  style="
                    height: 25px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    width: max-content;
                  "
                  :src="require(`../assets/newsession.svg`)"
                  @click="startNewSelfTest()"
                />
                <p class="fs-12 fw-600 center-align" style="opacity: 0.6">
                  Create Your Own Self Test
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div v-if="this.selfTest.length === 0">
                              <div class="main-recommendation-box">
                                  <div class="recommend-box recommendation-box relative lightPink-bgc card-radius-2" style="padding:12px;overflow: hidden; background: #E3E3E3 !important;">
                                      <div class="flex flex-between flex-column">
                                          <img style="height: 25px;margin-top: 5px;margin-bottom: 5px;" src="../assets/newsession.svg" @click="startNewSelfTest()" />
                                          <p class="fs-12 fw-600" style="opacity: 0.6;margin-left:5px">Start Your Own Practice</p>
                                      </div>
                                  </div>
                              </div>
                          </div> -->
      </div>
      <!-- Performance summary -->
      <div
        class="recommendation practice"
        :style="PlotlyPerformanceSummary === 'Success' ? 'display: block' : 'display: none'"
      >
        <!-- <div  class="recommendation practice "> -->
        <div class="recommend-top flex items-center">
          <h2 class="fs-15 purple-text fw-700">Performance Summary</h2>
        </div>
        <div
          v-dragscroll
          class="Plotly fs-12 fw-600 scroll-horizontal"
          ref="scroll_container9"
          @mousewheel.prevent="scrollX9"
        >
          <!-- <Plotly :data="this.data" :layout="this.layout" :display-mode-bar="false"></Plotly> -->
          <div id="myDiv"></div>
        </div>
      </div>
      <!-- <div id="myDiv" style="width: 500px; height 500px;" ref="myDiv"></div> -->
    </div>
    <div class="right-section">
      <!-- coverage report -->
      <div v-if="this.avgreport.length > 0">
        <h1 class="fs-18 fw-700 dark-text" style="margin-bottom: 12px">Coverage Report</h1>
        <Flicking
          :options="{ align: 'prev', circular: true }"
          :plugins="plugins"
          style="margin-bottom: 10px"
        >
          <div v-for="(img, index) in avgreport" :key="index">
            <div
              v-if="img.CardType === 'AVReport' && img.CardType !== 'LiveClass'"
              class="slideshow-container flex card-shadow card-radius card-border"
            >
              <div class="relative" style="padding: 15px; width: 100%">
                <div class="flex flex-between items-center" style="margin-bottom: 7px">
                  <h2 class="fw-700 fs-14" style="color: #ffffff !important">
                    Subject Coverage Report
                  </h2>
                  <img :src="require(`../assets/video 2.svg`)" alt="" width="24" />
                </div>
                <p class="white-text fs-11 fw-400" style="opacity: 0.7">
                  {{ formateTime(img.CreatedOn) }}
                </p>
                <a
                  class="yellow-btn fs-10 fw-600"
                  style="
                    width: 83px;
                    height: 20.03px;
                    margin-top: 25px;
                    line-height: 20.03px;
                    display: inline-block;
                  "
                  @click="watchVideo(img.VimeoURL)"
                  >Watch Video</a
                >
                <img class="class-image" :src="require(`../assets/Ellipse 419.svg`)" />
              </div>
            </div>
            <div
              v-if="img.CardType === 'LiveClass' && img.CardType !== 'AVReport'"
              class="slideshow1-container flex card-shadow card-radius"
            >
              <div class="fade relative" style="padding: 16px; margin-top: 15px">
                <h2 class="fs-18 fw-700 dark-text" style="color: #ffffff !important">
                  {{ img.Title }}
                </h2>
                <p class="fs-10 fw-400" style="color: #ffffff !important; margin-top: 15px">
                  <br /><span class="fs-15 fw-400">{{ img.SubjectName }}</span>
                </p>
                <p class="fs-11 fw-400" style="color: #ffffff !important; margin-top: 20px">
                  Duration {{ img.Duration }} min
                </p>
                <p class="fs-10 fw-600" style="color: #ffffff !important">
                  Faculty: <span class="fw-400"></span> {{ img.FacultyName }}
                </p>
                <a
                  @click="joinClass(img.Url)"
                  class="yellow-btn"
                  style="margin-top: 28px; line-height: 26px"
                  >Join Class</a
                >
                <img class="class-image" :src="require(`../assets/cardellipse.svg`)" />
              </div>
            </div>
          </div>
        </Flicking>
      </div>
      <!-- leaderboard -->
      <div class="leader-sec" v-if="leaderBoardData.length > 0">
        <div class="right-section">
          <div class="leaderboard_reward-section">
            <div class="leaderboard">
              <div class="flex flex-between items-center" style="margin-bottom: 11px">
                <h1 class="fs-18 fw-700 dark-text top-header">Leaderboard</h1>
                <a
                  class="fs-11 fw-600 skyblue-text flex items-center flex-between"
                  @click="showleaderboardDetail = !showleaderboardDetail"
                  style="margin-right: 3px"
                  >Details<i class="material-icons fs-15" style="margin-left: 3px">{{
                    showleaderboardDetail ? "expand_less" : "expand_more"
                  }}</i></a
                >
              </div>
              <div class="white-bgc card-radius card-shadow card-border leader-card">
                <div class="leader-body scroll-verticle" v-if="showleaderboardDetail === false">
                  <div
                    class="flex leader-top items-center flex-between"
                    v-for="(Leaders, index) in leaderBoardData"
                    :key="index"
                  >
                    <div class="flex" v-if="Object.keys(Leaders).length > 0">
                      <ProfileAvatar
                        style="border: none"
                        :username="Leaders.name.charAt(0)"
                        class="leader-avatar"
                        :bgColor="leaderBg[index] + '!important'"
                      ></ProfileAvatar>
                      <h3
                        class="fs-12 black-text fw-600"
                        style="white-space: nowrap; line-height: 20px"
                      >
                        {{
                          Leaders.name.length > 16
                            ? Leaders.name.slice(0, 16) + "..."
                            : Leaders.name
                        }}
                      </h3>
                    </div>
                    <div v-else style="position: relative; left: 8px; top: 2px">
                      <img :src="require(`../assets/saperator.svg`)" alt="" />
                    </div>
                    <h3 class="fs-12 skyblue-text fw-600 relative" style="left: -15px">
                      {{ Leaders.rank }}
                    </h3>
                  </div>
                </div>
                <div
                  class="leader-body scroll-verticle leader-details"
                  v-if="showleaderboardDetail === true"
                >
                  <div class="leader-top" v-for="(Leaders, index) in leaderBoardData" :key="index">
                    <div
                      class="flex items-center flex-between"
                      v-if="Object.keys(Leaders).length > 0"
                    >
                      <div class="flex">
                        <ProfileAvatar
                          style="border: none"
                          :username="Leaders.name.charAt(0)"
                          class="leader-avatar"
                          :bgColor="leaderBg[index] + '!important'"
                        ></ProfileAvatar>
                        <h3
                          class="fs-12 black-text fw-600"
                          style="white-space: nowrap; line-height: 20px"
                        >
                          {{
                            Leaders.name.length > 16
                              ? Leaders.name.slice(0, 16) + "..."
                              : Leaders.name
                          }}
                        </h3>
                      </div>
                      <h3
                        class="fs-12 skyblue-text fw-600 relative"
                        style="left: -15px; line-height: 20px"
                      >
                        {{ Leaders.rank }}
                      </h3>
                    </div>
                    <div
                      class="flex"
                      style="margin-left: 28px"
                      v-if="Object.keys(Leaders).length > 0"
                    >
                      <p class="flex fs-10 dark-text fw-600 leader-para">
                        <img :src="require(`../assets/Subtract.svg`)" style="margin-right: 8px" />
                        <span style="opacity: 0.4">{{ Leaders.Attempted }}</span>
                      </p>
                      <!-- <p class="flex fs-10 black-text fw-400 leader-para"><span class="material-icons material-symbols-outlined" style="font-size: 20px; color: rgb(46 43 43);margin-right: 5px;">help</span> {{Leader.SLScore}}</p> -->
                      <p class="flex fs-10 black-text fw-600 leader-para">
                        <img
                          :src="require(`../assets/target (1) 1.svg`)"
                          style="margin-right: 8px"
                        /><span style="opacity: 0.4">{{ Leaders.Accuracy }}</span>
                      </p>
                    </div>
                    <div v-else style="position: relative; left: 8px; top: 2px">
                      <img :src="require(`../assets/saperator.svg`)" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal2 :show="showAvVideo" :showHeader="false" :showCloseBtn="false">
      <template v-slot:body>
        <div class="flex flex-center earn-head">
          <p
            @click="
              showAvVideo = false;
              avVideoUrl = '';
            "
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons earn-close">close</span>
          </p>
        </div>
        <iframe
          :src="avVideoUrl"
          frameborder="0"
          allow=" fullscreen; picture-in-picture"
          allowfullscreen
          class="videoModal"
        ></iframe>
      </template>
    </Modal2>
  </div>
</template>

<script>
// import Vue from "vue";
// import VueHighcharts from "vue3-highcharts";
import moment from "moment";
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import ProfileAvatar from "vue-profile-avatar";

import Plotly from "plotly.js-dist";
import MobileAPI from "../Mobileapi";
import API from "../Api";
import Multiple from "../assets/Multiple.png";
import Modal2 from "../components/Model2.vue";

export default {
  data() {
    return {
      plugins: [
        new AutoPlay({
          duration: 7000,
          direction: "NEXT",
          stopOnHover: false,
        }),
      ],
      subjectCoverageData: [],
      showSubjectList: true,
      chapterwiseFilterList: [],
      tempChapterCardList: [],
      chapterwiseCardsList: [],
      overviewCardList: [],
      getNewStudentDashboardpra: [],
      selfTest: [],
      leaderBoardData: [],
      showleaderboardDetail: false,
      subjectListData: [],
      leaderBg: [],
      avgreport: [],
      randomImage: Multiple,
      avVideoUrl: "",
      showAvVideo: false,
      avreportlength: null,
      selectedSub: "",
      selectedSubIconUrl: "",
      selectSubjectId: null,
      dashboardUserDetails: null,
      dashboardUserId: null,
      dashboardClassId: null,
      dashboardCourseId: null,
      dashboardSubjectId: null,
      dashboardDomainName: null,
      data: [],
      layout: null,
      PlotlyPerformanceSummary: null,
      respCode: null,
      getclass: null,
      activeChapter: null,
      activeSubject: null,
      selfSubjectid: null,
      className: "",
    };
  },
  //   beforeCreate() {
  //     document.querySelector("body").setAttribute("style", "background:#E0E4F0");
  //   },

  //   beforeUnmount() {
  //     document.querySelector("body").setAttribute("style", "background:#f6f1ec");
  //   },
  activated() {
    this.dashboardUserDetails = JSON.parse(localStorage.getItem("SubjectCoverageParams"));
    localStorage.removeItem("SubjectCoverageParams");
    if (this.dashboardUserDetails) {
      this.dashboardDomainName = this.dashboardUserDetails.dashboardDomainName;
      this.dashboardUserId = Number(this.dashboardUserDetails.dashboardUserId);
      this.dashboardClassId = Number(this.dashboardUserDetails.dashboardClassId);
      this.dashboardCourseId = Number(this.dashboardUserDetails.dashboardCourseId);
      this.dashboardSubjectId = Number(this.dashboardUserDetails.dashboardSubjectId);
      this.selectedSub = this.dashboardUserDetails.dashboardSubjectName;
      this.selectedSubIconUrl = this.dashboardUserDetails.dashboardSubjectIcon;
      this.className = this.dashboardUserDetails.dashboardClassName;
      this.getSubjectCoverageData(
        this.dashboardUserId,
        this.dashboardClassId,
        this.dashboardCourseId,
        this.dashboardSubjectId,
        this.selectedSub,
        this.selectedSubIconUrl,
      );
      const userDetails = {
        domainName: this.dashboardDomainName,
        userId: this.dashboardUserId,
        courseId: this.dashboardCourseId,
        classId: this.dashboardClassId,
        subjectId: this.dashboardSubjectId,
        selectedSub: this.selectedSub,
        selectedSubIcon: this.selectedSubIconUrl,
        className: this.className,
      };
      localStorage.setItem("subjectCoverageUserDetails", JSON.stringify(userDetails));
    } else {
      const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      this.className = userDetail.className;
      this.getSubjectCoverageData(
        userDetail.userId,
        userDetail.classId,
        userDetail.courseId,
        userDetail.subjectId,
        userDetail.selectedSub,
        userDetail.selectedSubIcon,
      );
      this.dashboardDomainName = userDetail.domainName;
    }

    this.leaderBg = [
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
    ];
  },
  components: {
    // Avatar,
    ProfileAvatar,
    // carousel,
    // Plotly,
    Modal2,
    Flicking,
    // VueHighcharts,
  },
  methods: {
    formateTime(value) {
      if (value) {
        return moment(value).format("DD MMMM YYYY");
      }
      return 0;
    },
    getSubjectCoverageData(userId, classId, courseId, subjectId, selectedSub, selectedSubIcon) {
      this.$store.dispatch("showLoader", true);
      MobileAPI.getSubjectCoverageData(
        {
          userId,
          courseId,
          classId,
          subjectId,
        },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.subjectCoverageData = response.data;
          if (Object.keys(this.subjectCoverageData).length > 0) {
            this.subjectListData = this.subjectCoverageData.subjectsList
              ? this.subjectCoverageData.subjectsList
              : [];
            if (this.subjectCoverageData.chapterWiseCards) {
              this.chapterwiseFilterList = this.subjectCoverageData.chapterWiseCards.filters
                ? this.subjectCoverageData.chapterWiseCards.filters
                : [];
              this.chapterwiseCardsList = this.subjectCoverageData.chapterWiseCards.cards
                ? this.subjectCoverageData.chapterWiseCards.cards
                : [];
              this.tempChapterCardList = this.chapterwiseCardsList;
            }
            this.overviewCardList = this.subjectCoverageData.overviewCards
              ? this.subjectCoverageData.overviewCards
              : [];
            if (this.subjectCoverageData.recommendations) {
              this.getNewStudentDashboardpra = this.subjectCoverageData.recommendations.Practice
                ? this.subjectCoverageData.recommendations.Practice
                : [];
              this.selfTest = this.subjectCoverageData.recommendations.SelfTest
                ? this.subjectCoverageData.recommendations.SelfTest
                : [];
            }
            this.leaderBoardData = this.subjectCoverageData.leaderboard
              ? this.subjectCoverageData.leaderboard
              : [];
          }

          this.selectedSub = selectedSub;
          this.selectedSubIconUrl = selectedSubIcon;
          this.dashboardUserId = userId;
          this.dashboardCourseId = courseId;
          this.dashboardClassId = classId;
          this.dashboardSubjectId = subjectId;
          const data = {
            //   userId: 71815,
            //   courseId: 2,
            //   classId: 8,
            //   subjectId: 2,
            userId: Number(this.dashboardUserId),
            courseId: Number(this.dashboardCourseId),
            classId: Number(this.dashboardClassId),
            subjectId: Number(this.dashboardSubjectId),
            //   userId: this.dashboardUserId,
            //   courseId: this.dashboardCourseId,
            //   classId: this.dashboardClassId,
            //   subjectId: this.dashboardSubjectId,
          };
          MobileAPI.getStudentDashboardPlotly(data, (response1) => {
            this.PlotlyPerformanceSummary = response1.message;
            this.respCode = response1.responseCode;
            this.data = response1.data.data;
            this.layout = response1.data.layout;
            Plotly.newPlot("myDiv", this.data, this.layout);
          });

          if (Object.keys(this.subjectCoverageData.chapterWiseCards).length > 0) {
            this.$store.dispatch("showLoader", true);
            MobileAPI.getStudentAvgReport(
              this.dashboardUserId,
              this.dashboardSubjectId,
              (resp) => {
                this.avgreport = resp.data;
                this.avreportlength = resp.data.length;
                this.$store.dispatch("showLoader", false);
              },
              () => {
                this.$store.dispatch("showLoader", false);
              },
            );
            if (this.chapterwiseFilterList != null) {
              const defaultCard = Object.values(this.chapterwiseFilterList).filter(
                (a) => a.isDefault,
              );
              if (defaultCard != null) {
                this.index = Object.values(this.chapterwiseFilterList).findIndex(
                  (a) => a.isDefault,
                );
                this.filterChapterCards(defaultCard[0], this.index);
              }
            }
            //   this.filterChapterCards(Object.values(this.chapterwiseFilterList)[0], Object.keys(this.chapterwiseFilterList)[0]);
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    selectSubject(subjectData) {
      this.subjectCoverageData = [];
      this.selectedSub = subjectData.subjectName;
      this.showSubjectList = true;
      this.selectSubjectId = subjectData.subjectId;
      this.dashboardSubjectId = this.selectSubjectId;
      this.selectedSubIconUrl = subjectData.subjectIcon;
      const tempUserDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      this.dashboardDomainName = tempUserDetail.domainName;
      this.dashboardUserId = tempUserDetail.userId;
      this.dashboardCourseId = tempUserDetail.courseId;
      this.dashboardClassId = tempUserDetail.classId;
      localStorage.removeItem("subjectCoverageUserDetails");
      const userDetails = {
        domainName: this.dashboardDomainName,
        userId: this.dashboardUserId,
        courseId: this.dashboardCourseId,
        classId: this.dashboardClassId,
        subjectId: this.dashboardSubjectId,
        selectedSub: this.selectedSub,
        selectedSubIcon: this.selectedSubIconUrl,
        className: this.className,
      };
      localStorage.setItem("subjectCoverageUserDetails", JSON.stringify(userDetails));
      const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      this.dashboardDomainName = userDetail.domainName;
      this.className = userDetail.className;
      if (this.dashboardSubjectId && this.selectSubjectId) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getSubjectCoverageData(
          {
            userId: this.dashboardUserId,
            courseId: this.dashboardCourseId,
            classId: this.dashboardClassId,
            subjectId: this.dashboardSubjectId,
          },
          (response) => {
            this.$store.dispatch("showLoader", false);
            this.subjectCoverageData = response.data;
            // this.chapterwiseFilterList = this.subjectCoverageData.chapterWiseCards.filters;
            // this.chapterwiseFilterList = this.subjectCoverageData.chapterWiseCards.filters;
            // this.chapterwiseCardsList = this.subjectCoverageData.chapterWiseCards.cards;
            // this.tempChapterCardList = this.subjectCoverageData.chapterWiseCards.cards;
            // this.overviewCardList = this.subjectCoverageData.overviewCards;
            // this.getNewStudentDashboardpra = this.subjectCoverageData.recommendations.Practice;
            // this.selfTest = this.subjectCoverageData.recommendations.SelfTest;
            // this.leaderBoardData = this.subjectCoverageData.leaderboard;
            if (Object.keys(this.subjectCoverageData).length > 0) {
              if (this.subjectCoverageData.chapterWiseCards) {
                this.chapterwiseFilterList = this.subjectCoverageData.chapterWiseCards.filters
                  ? this.subjectCoverageData.chapterWiseCards.filters
                  : [];
                this.chapterwiseCardsList = this.subjectCoverageData.chapterWiseCards.cards
                  ? this.subjectCoverageData.chapterWiseCards.cards
                  : [];
                this.tempChapterCardList = this.chapterwiseCardsList;
              }
              this.overviewCardList = this.subjectCoverageData.overviewCards
                ? this.subjectCoverageData.overviewCards
                : [];
              if (this.subjectCoverageData.recommendations) {
                this.getNewStudentDashboardpra = this.subjectCoverageData.recommendations.Practice
                  ? this.subjectCoverageData.recommendations.Practice
                  : [];
                this.selfTest = this.subjectCoverageData.recommendations.SelfTest
                  ? this.subjectCoverageData.recommendations.SelfTest
                  : [];
              }
              this.leaderBoardData = this.subjectCoverageData.leaderboard
                ? this.subjectCoverageData.leaderboard
                : [];
            }

            if (Object.keys(this.subjectCoverageData.chapterWiseCards).length > 0) {
              this.$store.dispatch("showLoader", true);
              MobileAPI.getStudentAvgReport(
                this.dashboardUserId,
                this.selectSubjectId,
                (resp) => {
                  this.avgreport = resp.data;
                  this.avreportlength = resp.data.length;
                  this.$store.dispatch("showLoader", false);
                },
                () => {
                  this.$store.dispatch("showLoader", false);
                },
              );
              if (this.chapterwiseFilterList != null) {
                const defaultCard = Object.values(this.chapterwiseFilterList).filter(
                  (a) => a.isDefault,
                );
                if (defaultCard != null) {
                  this.index = Object.values(this.chapterwiseFilterList).findIndex(
                    (a) => a.isDefault,
                  );
                  this.filterChapterCards(defaultCard[0], this.index);
                }
              }
            }
          },
          () => {
            this.$store.dispatch("showLoader", false);
          },
        );
      }
    },
    filterChapterCards(chapterFilter, index) {
      const sortArray = (arr1, arr2) => {
        arr2.sort((a, b) => {
          const aKey = a.courseChapterId;
          const bKey = b.courseChapterId;
          return arr1.indexOf(aKey) - arr1.indexOf(bKey);
        });
      };
      sortArray(chapterFilter.order, this.chapterwiseCardsList);
      Object.values(this.chapterwiseFilterList).forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.isDefault = false;
      });
      Object.values(this.chapterwiseFilterList)[index].isDefault = true;
    },
    scrollX1(e) {
      this.$refs.scroll_container1.scrollLeft += e.deltaY;
    },
    scrollX2(e) {
      this.$refs.scroll_container2.scrollLeft += e.deltaY;
    },
    scrollX3(e) {
      this.$refs.scroll_container3.scrollLeft += e.deltaY;
    },
    scrollX4(e) {
      this.$refs.scroll_container4.scrollLeft += e.deltaY;
    },
    scrollX5(e) {
      this.$refs.scroll_container5.scrollLeft += e.deltaY;
    },
    scrollX6(e) {
      this.$refs.scroll_container6.scrollLeft += e.deltaY;
    },
    scrollX7(e) {
      this.$refs.scroll_container7.scrollLeft += e.deltaY;
    },
    scrollX8(e) {
      this.$refs.scroll_container8.scrollLeft += e.deltaY;
    },
    scrollX9(e) {
      this.$refs.scroll_container9.scrollLeft += e.deltaY;
    },
    watchVideo(videoUrl) {
      this.avVideoUrl = videoUrl;
      this.showAvVideo = true;
    },
    startPractice(selectedItem) {
      this.$store.dispatch("showLoader", true);
      //   const userDetail = JSON.parse(localStorage.getItem("subjectCoverageUserDetails"));
      const user = this.$store.getters.user;
      this.userCourseId = Number(this.dashboardCourseId);
      if (this.userCourseId !== 1) {
        const data = {
          courseChapterIds: selectedItem.courseChapterId,
          questionTypeIds: "1,3,4,5",
          questionLevelIds: "1,2",
          isPastYear: 0,
        };
        MobileAPI.getPracticeQuestion(user.UserId, data, (response) => {
          this.practiceQuestionList = response.data;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.messages = this.practiceQuestionList.messages;
            this.prompts = this.practiceQuestionList.prompts;
            this.requestBody = {
              subjectId: Number(selectedItem.subjectId),
              topicId: 0,
              difficultyLevel: 1,
              courseId: Number(this.dashboardCourseId),
              courseChapterIdList: [selectedItem.courseChapterId],
              chapterIdList: [selectedItem.chapterId],
            };
            MobileAPI.startNewPracticeSession(this.requestBody, (practice) => {
              this.$store.dispatch("showLoader", false);
              if (practice.responseCode === 200) {
                this.USessionId = practice.data[0].UserTestSessionId;
                if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                  this.$router.push({
                    name: "PracticeSession",
                    params: {
                      questionList: JSON.stringify(this.questionList),
                      kscList: JSON.stringify(this.kscList),
                      chapterList: JSON.stringify(this.chapterList),
                      subjectList: JSON.stringify(this.subjectList),
                      messages: JSON.stringify(this.messages),
                      prompts: JSON.stringify(this.prompts),
                      UserTestSessionId: this.USessionId,
                      coureId: this.dashboardCourseId,
                      subjectName: selectedItem.subjectName,
                      subjectId: selectedItem.subjectId,
                      className: this.className,
                      chapters: selectedItem.chapterName,
                      isSubjective: 0,
                      isPastYear: 0,
                    },
                  });
                }
              }
            });
          }
        });
      } else if (this.userCourseId === 1) {
        const data = {
          courseChapterIds: selectedItem.courseChapterId,
          questionTypeIds: "1,3,4,5",
          questionLevelIds: "1",
        };
        MobileAPI.getPracticeQuestion(user.UserId, data, (response) => {
          this.practiceQuestionList = response.data;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.messages = this.practiceQuestionList.messages;
            this.prompts = this.practiceQuestionList.prompts;
            this.requestBody = {
              subjectId: Number(selectedItem.subjectId),
              topicId: 0,
              difficultyLevel: 1,
              courseId: Number(this.dashboardCourseId),
              courseChapterIdList: [selectedItem.courseChapterId],
              chapterIdList: [selectedItem.chapterId],
            };
            MobileAPI.startNewPracticeSession(this.requestBody, (practice) => {
              this.$store.dispatch("showLoader", false);
              if (practice.responseCode === 200) {
                this.USessionId = practice.data[0].UserTestSessionId;
                if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                  this.$router.push({
                    name: "PracticeSession",
                    params: {
                      questionList: JSON.stringify(this.questionList),
                      kscList: JSON.stringify(this.kscList),
                      chapterList: JSON.stringify(this.chapterList),
                      subjectList: JSON.stringify(this.subjectList),
                      messages: JSON.stringify(this.messages),
                      prompts: JSON.stringify(this.prompts),
                      UserTestSessionId: this.USessionId,
                      coureId: this.dashboardCourseId,
                      subjectName: selectedItem.subjectName,
                      subjectId: selectedItem.subjectId,
                      className: this.className,
                      chapters: selectedItem.chapterName,
                      isSubjective: 0,
                      isPastYear: 0,
                    },
                  });
                }
              }
            });
          }
        });
      }
    },
    startNewPractice() {
      this.$router.push({ name: "PracticeHome" });
    },
    startSelfTestFull(chapter) {
      const newSelfTestData = {
        testDurationInMin: 30,
        isFullSyllabus: 0,
        subjectId: chapter.subjectId,
        courseId: this.dashboardCourseId,
        difficultyLevel: 2,
        questionCount: 10,
        courseChapterIds: chapter.courseChapterId,
        testName: `${chapter.subjectName}, ${chapter.chapterName}-${moment().format(
          "DD-MM-YYYY, hh:mm A",
        )}`,
        classId: this.dashboardClassId,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.addStudentTestNew(
        newSelfTestData,
        (response) => {
          if (response === null || response.data === null || response.data.TestId <= 0) {
            this.selfTestId = response.data.TestId;
            window.M.toast({ html: "There was some issue in creating the test." });
            this.$store.dispatch("showLoader", false);
          } else {
            this.selfTestId = response.data.TestId;
            MobileAPI.getSelfTestQuestions(
              { selfTestId: response.data.TestId },
              (response2) => {
                const questionList = response2.data.questions;
                if (
                  response2.data.userMessages !== undefined &&
                  response2.data.userMessages.length > 0
                ) {
                  this.messages = response2.data.userMessages[0];
                  this.showNoQuestionModal = true;
                  this.$store.dispatch("showLoader", false);
                } else {
                  const newQuestionList = [];
                  questionList.forEach((obj) => {
                    newQuestionList.push({
                      QuestionId: obj.QuestionId,
                      SerialNumber: obj.SerialNo,
                    });
                  });
                  const data = {
                    selfTestId: response.data.TestId,
                    studentTestQuestions: newQuestionList,
                  };
                  MobileAPI.addSelfTestQuestions(data, (resp) => {
                    const questionAdded = resp.data;
                    API.startTest({ testId: response.data.TestId, type: 1 }, (test) => {
                      this.$router.push({
                        name: "ConductTest",
                        params: { testUserId: test.testUserId, testType: 1 },
                      });
                      this.$store.dispatch("showLoader", false);
                    });
                    console.log(questionAdded);
                  });
                }
              },
              () => {
                this.$store.dispatch("showLoader", false);
              },
            );
          }
        },
        () => {
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    startNewSelfTest() {
      this.$router.push({ path: "/student/self-test" });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking-inline.css");

.subject-coverage {
  font-family: "Open Sans" !important;
  margin-top: 25px;
}

/* Custom CSS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /*Standard */
  margin: 0;
}

@font-face {
  font-family: PackMan;
  src: url(../font/pac-font.ttf);
}

* {
  box-sizing: border-box;
}

.dark-text {
  color: #333333 !important;
}

.black-text2 {
  color: #242c3c;
}

.black-text {
  color: #000000 !important;
}

.skyblue-text {
  color: #3751ff !important;
}

.skyblue-text2 {
  color: #5c8eff !important;
}

.purple-text {
  color: #642c90 !important;
}

.purple-text2 {
  color: #9470c1 !important;
}

.orange-text {
  color: #f59e00 !important;
}

.light-gray-text {
  color: #666666;
}

.white-bgc {
  background: #fff;
}

.orange-bgc {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.lightPink-bgc {
  background: #ffecdb;
}

.lightGreen-bgc {
  background: #ebffe7;
}

.test-bgc {
  background: linear-gradient(98.34deg, #e9ccff 11.31%, #f4e6ff 88.13%);
}

.purple-bgc {
  background: #642c90;
}

.card-border {
  border: 1px solid #e3e3e3;
}

.card-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.card-radius {
  border-radius: 5px;
}

.card-radius-2 {
  border-radius: 6px;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.block {
  display: block;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.max-content {
  width: max-content;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-11 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.m-0 {
  margin: 0;
}

.mt-31 {
  margin-top: 31px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-24 {
  margin-bottom: 24px;
}

.w-105 {
  width: 105px;
}

.w-69 {
  width: 69px;
}

.w-75 {
  width: 75px;
}

.p-15 {
  padding: 15px;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 0px;
}

.scroll-horizontal::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.select-box.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.yellow-btn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  outline: none;
  height: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  z-index: 20;
}

.gray-btn {
  height: 21px;
  background: #f8f8f8;
  border: 1px solid #d6daf7;
  border-radius: 4px;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  text-align: center;
  opacity: 0.65;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;
}

/* Custom CSS */
/* Left Section */
.left-section {
  max-width: 355px;
  min-width: 310px;
  padding: 15px 20px 5px;
  height: max-content;
  margin-bottom: 10px;
}

.secondary-header {
  height: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #642c90;
}

.subject-select {
  width: 240px;
  height: 67px;
  position: relative;
  margin-top: 12px;
  padding: 10px 16px;
}

.subject-select .subject-img {
  position: absolute;
  right: 17px;
  top: 13px;
  opacity: 0.1;
  width: 55px;
}

.subject-list {
  width: 100%;
  max-height: 229px;
  position: absolute;
  left: 0;
  z-index: 20;
  top: -1px;
  background: #ffffff;
  border: 1px solid #333333;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.15);
  border-radius: 5px;
  padding: 10px 0;
}

.subject-list a {
  padding-left: 15px;
  margin-bottom: 10px;
}

.sublist p:first-child(1) {
  margin-top: 10px;
}

.sublist p {
  color: #333333;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 8px 15px;
}

.sublist p:hover {
  background: #f6f8fb;
}

.chapter-wise-detail {
  margin-top: 22px;
  margin-bottom: 20px;
}

.chapter-wise-detail h2 {
  margin-bottom: 10px;
}

.chapter-wise-detail .gray-btn.active {
  opacity: 1;
  font-weight: 600;
  background: #f0f2ff;
}

.chapter-wise-content {
  width: 100%;
  max-height: calc(100vh - 200px);
}

.chapter-wise-card {
  padding: 10px 15px;
}

.progress-value {
  margin-top: 14px;
  margin-bottom: 8px;
}

.progress1 {
  position: relative;
  max-width: 100%;
  height: 6px;
  background: #f2f3f8;
  margin-bottom: 15px;
}

.progress-bar1 {
  position: absolute;
  height: 100%;
  border-radius: 30px;
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: #ee9b05;
}

/*Middle section*/
.middle-section {
  max-width: 565px;
  min-width: 300px;
  width: 100%;
  margin: 0 20px;
}

.middle-card-box {
  padding: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.card-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
  margin-top: 11px;
}

.card-box {
  position: relative;
  max-width: 168px;
  height: 90px;
  background: #faf5ff;
  padding: 10px 15px 15px;
  color: #9470c1;
}

.card-box h3 {
  margin-bottom: 9px;
}

.card-box::after {
  /*position: absolute;
      content: "";
      right: -70%;
      bottom: -45%;
      background: url('../assets/bookmark-page\ 1.svg') no-repeat;
      z-index: 10;
      width: 100%;
      height: 100%;*/
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: url("../assets/bookmark-page\ 1.svg") no-repeat;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-position: 95% 83% !important;
  z-index: 0;
}

.card-box:nth-child(2) {
  background: #f2f6ff;
  color: #5c8eff;
}

.card-box:nth-child(2):after {
  background: url("../assets/Question\ Att.svg") no-repeat;
}

.card-box:nth-child(3) {
  background: #f4faff;
  color: #427ca9;
}

.card-box:nth-child(3):after {
  background: url("../assets/hourglass\ 4.svg") no-repeat;
}

.card-box:nth-child(4) {
  background: #fffaf0;
  color: #ee9b05;
}

.card-box:nth-child(4):after {
  background: url("../assets/success\ 1.svg") no-repeat;
}

.card-box:nth-child(5) {
  background: #fff4f5;
  color: #ef727a;
}

.card-box:nth-child(5):after {
  background: url("../assets/aim\ 1.svg") no-repeat;
}

.card-box:nth-child(6) {
  background: #f3fffc;
  color: #36af8d;
}

.card-box:nth-child(6):after {
  background: url("../assets/Speed2.svg") no-repeat;
}

/*Right Section */
.right-section {
  width: 216px;
  margin-bottom: 10px;
}

/* Practice */
.recommend-section {
  max-width: 570px;
  min-width: 220px;
  padding: 0 15px;
}

.recommend-box {
  width: 170px;
  height: 80px;
  border-radius: 6px;
}

.recommendation {
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 16px 15px;
  margin-bottom: 15px;
  width: 100%;
}

.recommendation .Plotly {
  /* font-size: 55px; */
  margin-top: 15px;
}

.recommendation:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.recommendation-content {
  margin-top: 15px;
}

.recommendation-content .main-recommendation-box {
  width: 170px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.recommendation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.recommendation-box .progress {
  position: absolute;
  transform: rotate(180deg);
  width: 4px;
  height: 56px;
  background: #f7f7f7;
  right: 9px;
  top: 7%;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.progress-bar1.transparent {
  background: transparent;
}

.progress-bar1.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar1.yellow {
  background: yellow;
}

.progress-bar1.blue {
  background: blue;
}

.progress-bar1.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.assignment .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.assignment .recommendation-box:hover .yellow-btn {
  opacity: 1;
}

.recommend-box .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.recommend-box:hover .yellow-btn {
  opacity: 1;
  z-index: 2;
}

/*.practice .recommendation-box:hover .yellow-btn {
      opacity: 1;
  }*/

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

/*Leaderboard */
.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

.leaderboard .leader-body {
  max-height: 390px;
}

.leaderboard .leader-body.leader-details .leader-top {
  padding: 6px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body.leader-details .leader-top:first-child {
  padding-top: 0;
}

/*
  .leaderboard .leader-body .leader-top {
      padding: 10px 0px;
      border-bottom: 1px solid #E0E4F0;
  }*/

.leaderboard .leader-body .leader-top:first-child {
  padding-top: 0px;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
  padding: 10px 0px 0px;
}

.leader-para {
  line-height: 20px;
  margin-right: 16px;
  width: 50px;
}

.leader-card {
  padding: 22px 15px;
  position: relative;
  max-height: 433px;
}

.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  background: #fc8686 !important;
  color: white !important;
  margin-right: 10px;
}

.leaderboard_reward-section {
  min-width: 216px;
}

/*.leaderboard {
      margin-top: 18px;
  }*/

.leaderboard-content {
  padding: 16px 15px 4px;
  max-height: 470px;
  height: 100%;
}

.leaderboard .leader-body .leader-top {
  padding: 8px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
}

.leader-para {
  line-height: 20px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

/*Coverage report*/
.slideshow-container {
  min-width: 100%;
  height: 203px;
  background: #5a5fe6;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 5px;
  min-width: auto;
  padding: 2px 0;
  height: auto;
  overflow: hidden;
}

.class-image {
  position: absolute;
  right: -10px;
  top: 60px;
  z-index: 0;
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}

.earn-close {
  background-color: #ffffff !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.videoModal {
  width: 600px;
  height: 340px;
  padding: 12px 11px 5px;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

/*Media Query*/
@media screen and (max-width: 1200px) {
  .card-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .subject-coverage {
    flex-direction: column;
    align-items: center;
  }

  .left-section {
    max-width: 565px;
    width: 95%;
    margin-bottom: 15px;
  }

  .middle-section {
    width: 95%;
  }

  .card-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .videoModal {
    width: 100% !important;
    height: auto !important;
    padding: 10px 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .card-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* .js-plotly-plot {
      display: none !important;
  } */
</style>
